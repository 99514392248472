import React from 'react';

import { useIntl } from '@kamernet/core/Intl';
import { Locale } from '@kamernet/core/Intl/types';

export const useCookieBanner = () => {
  const { locale } = useIntl();

  const changeCookieBannerLanguage = React.useCallback(() => {
    if (typeof window !== 'undefined') {
      window.Optanon.changeLanguage(
        locale === Locale.en ? Locale.nl : Locale.en,
      );
    }
  }, [locale]);

  return { changeCookieBannerLanguage };
};
