import React from 'react';

import { useRouter } from 'next/router';

import MenuIcon from '@mui/icons-material/Menu';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import { Button, Popover, IconButton } from '@mui/material';
import classnames from 'classnames';

import { trackExperiment } from '@kamernet/core/Analytics/utilities/trackExperiment';
import { MyConversationsInboxType } from '@kamernet/core/ApiClient/Kamernet';
import { AppServicesContext } from '@kamernet/core/AppServices';
import { AuthContext } from '@kamernet/core/Auth/AuthContext';
import { AuthSource, AuthAction } from '@kamernet/core/Auth/types';
import { useAuthenticationRouting } from '@kamernet/core/Auth/useAuthenticationRouting';
import { useCookieBanner } from '@kamernet/core/Cookie/useCookieBanner';
import { useIntl } from '@kamernet/core/Intl';
import { Locale } from '@kamernet/core/Intl/types';
import { ROUTES } from '@kamernet/core/Routing';
import { NavLink } from '@kamernet/core/Routing/NavLink';
import ArticleIcon from '@kamernet/icons/Article.svg';
import BadgeIcon from '@kamernet/icons/Badge.svg';
import BuildingIcon from '@kamernet/icons/Building.svg';
import DoorIcon from '@kamernet/icons/Door.svg';
import GlobeIcon from '@kamernet/icons/Globe.svg';
import ShieldIcon from '@kamernet/icons/GppGood.svg';
import GroupIcon from '@kamernet/icons/Group.svg';
import HandCoinsIcon from '@kamernet/icons/HandCoins.svg';
import HeartIcon from '@kamernet/icons/Heart.svg';
import HelpIcon from '@kamernet/icons/Help.svg';
import InfoIcon from '@kamernet/icons/Info.svg';
import LightbulbIcon from '@kamernet/icons/Lightbulb.svg';
import LogoutIcon from '@kamernet/icons/Logout.svg';
import MailIcon from '@kamernet/icons/Mail.svg';
import SearchIcon from '@kamernet/icons/Search.svg';
import SettingsIcon from '@kamernet/icons/Settings.svg';
import UserIcon from '@kamernet/icons/User.svg';
import { CommonStyles } from '@kamernet/styles/CommonStyles';

import { UserProfileImage } from '@kamernet/modules/User/components/UserProfileImage';

import styles from './HeaderDesktop.module.css';
import { LogoLink } from './LogoLink';

export const HeaderDesktop = () => {
  const router = useRouter();
  const { isAuthenticated, userProfile, signout } = AuthContext.useContext();
  const { T, locale } = useIntl();
  const userHasPremium = userProfile?.haspremiumaccount;

  const { featureFlags } = AppServicesContext.useContext();

  const buyPremiumFlagState =
    featureFlags.show_buy_premium_burger_menu_desktop === 'on';

  const showBuyPremiumLink = buyPremiumFlagState && !userHasPremium;

  React.useEffect(() => {
    trackExperiment({
      experimentName: 'Subscribe CTA in Menu',
      condition: buyPremiumFlagState,
    });
  }, [buyPremiumFlagState]);

  const { navigateToAuthentication } = useAuthenticationRouting();
  const { changeCookieBannerLanguage } = useCookieBanner();

  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const handlePopoverOpenButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => setPopoverAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setPopoverAnchorEl(null);
  const isPopoverOpen = popoverAnchorEl !== null;

  return (
    <React.Fragment>
      <nav className={styles.root}>
        <LogoLink />
        <NavLink.Anchor
          href={ROUTES.Search.url({}, locale)}
          className={classnames(styles.navLink, styles.searchForRoomLink)}
          variant="linkMedium"
          underline="none"
        >
          <SearchIcon fontSize="small" />
          {T('LBL_HEADER_SEARCH')}
        </NavLink.Anchor>
        <div className={styles.centralSection}>
          <Button
            variant="contained"
            color="tertiary"
            size="small"
            component={NavLink.Anchor}
            href={
              isAuthenticated
                ? ROUTES.LCFStart.url({}, locale)
                : ROUTES.RentOutRoom.url({}, locale)
            }
          >
            {T('LBL_HEADER_CREATE_ADVERT')}
          </Button>
        </div>
        {!isAuthenticated && (
          <React.Fragment>
            <Button
              className={styles.navLink}
              variant="text"
              size="large"
              onClick={() =>
                navigateToAuthentication({
                  action: AuthAction.Login,
                  source: AuthSource.ClickedNavbar,
                })
              }
            >
              {T('LBL_LOGIN')}
            </Button>
            <Button
              className={styles.navLink}
              variant="text"
              size="large"
              onClick={() =>
                navigateToAuthentication({
                  action: AuthAction.Signup,
                  source: AuthSource.ClickedNavbar,
                })
              }
            >
              {T('LBL_BUTTON_REGISTER')}
            </Button>
          </React.Fragment>
        )}
        {isAuthenticated && (
          <React.Fragment>
            <NavLink.Anchor
              href={ROUTES.MyListings.url({}, locale)}
              className={classnames(styles.navLink, styles.myAdvertsLink)}
              underline="none"
            >
              {T('LBL_ADVERTS_HEADER')}
            </NavLink.Anchor>
            <NavLink.Anchor
              href={ROUTES.MyConversations.url(
                { inboxType: MyConversationsInboxType.Default },
                locale,
              )}
              className={styles.navLink}
              underline="none"
            >
              <MailIcon />
            </NavLink.Anchor>
            <NavLink.Anchor
              href={ROUTES.MyFavoriteRooms.url({}, locale)}
              className={styles.navLink}
              underline="none"
            >
              <HeartIcon />
            </NavLink.Anchor>
          </React.Fragment>
        )}
        {isAuthenticated && (
          <div className={styles.myImage}>
            <UserProfileImage
              imageKey={userProfile?.userphoto}
              width={30}
              height={30}
            />
          </div>
        )}
        <IconButton
          className={styles.popoverOpenButton}
          onClick={handlePopoverOpenButtonClick}
        >
          <MenuIcon fontSize="small" color="secondary" />
        </IconButton>
      </nav>
      <Popover
        className={CommonStyles.popoverBorderRadiusMedium}
        open={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <ul className={styles.popoverContent}>
          <li>
            <NavLink.Anchor
              href={{ pathname: router.pathname, query: router.query }}
              locale={locale === Locale.en ? 'nl' : 'en'}
              underline="none"
              onClick={changeCookieBannerLanguage}
            >
              <GlobeIcon />

              <div className={styles.languageContainer}>
                <span>{T('LBL_GLOBAL_LANGUAGE')}</span>
                <span> {locale === Locale.en ? '🇳🇱' : '🇬🇧'}</span>
              </div>
            </NavLink.Anchor>
          </li>
          {isAuthenticated && (
            <React.Fragment>
              {showBuyPremiumLink && (
                <li className={styles.popoverBorder}>
                  <NavLink.Anchor
                    href={ROUTES.PremiumListing.url({}, locale)}
                    underline="none"
                  >
                    <StarsOutlinedIcon />
                    {T('LBL_SUBSCRIBE_NOW')}
                  </NavLink.Anchor>
                </li>
              )}

              <li className={styles.popoverBorder}>
                <NavLink.Anchor
                  href={ROUTES.PublicProfile.url({}, locale)}
                  underline="none"
                >
                  <BadgeIcon />
                  {T('LBL_MY_PROFILE')}
                </NavLink.Anchor>
              </li>
              <li>
                <NavLink.Anchor
                  href={ROUTES.MyListings.url({}, locale)}
                  underline="none"
                >
                  <BuildingIcon />
                  {T('LBL_ADVERTS_MENU')}
                </NavLink.Anchor>
              </li>
            </React.Fragment>
          )}
          {showBuyPremiumLink && !isAuthenticated && (
            <li className={styles.popoverBorder}>
              <NavLink.Anchor
                href={ROUTES.PremiumListing.url({}, locale)}
                underline="none"
              >
                <StarsOutlinedIcon />
                {T('LBL_SUBSCRIBE_NOW')}
              </NavLink.Anchor>
            </li>
          )}
          <li className={styles.popoverBorder}>
            <NavLink.Anchor
              href={ROUTES.Search.url({}, locale)}
              underline="none"
            >
              <DoorIcon />
              {T('LBL_SEARCH_ROOM')}
            </NavLink.Anchor>
          </li>
          <li>
            <NavLink.Native
              href={ROUTES.SearchTenant.url({}, locale, 'prefix')}
              underline="none"
            >
              <GroupIcon />
              {T('LBL_SEARCH_TENANTS_MENU')}
            </NavLink.Native>
          </li>
          {isAuthenticated && (
            <React.Fragment>
              <li className={styles.popoverBorder}>
                <NavLink.Anchor
                  href={ROUTES.Cashback.url({}, locale)}
                  underline="none"
                >
                  <HandCoinsIcon />
                  {T('LBL_GLOBAL_CASHBACK')}
                </NavLink.Anchor>
              </li>
              <li>
                <NavLink.Anchor
                  href={ROUTES.MyAccount.url({}, locale)}
                  underline="none"
                >
                  <UserIcon />
                  {T('LBL_GLOBAL_MYACCOUNT')}
                </NavLink.Anchor>
              </li>
              <li>
                <NavLink.Anchor
                  href={ROUTES.Settings.url({}, locale)}
                  underline="none"
                >
                  <SettingsIcon />
                  {T('LBL_GLOBAL_SETTINGS')}
                </NavLink.Anchor>
              </li>
            </React.Fragment>
          )}
          <li className={styles.popoverBorder}>
            <NavLink.Anchor
              href={ROUTES.HowDoesItWorkTenant.url({}, locale)}
              underline="none"
            >
              <ArticleIcon />
              {T('LBL_HOW_DOES_IT_WORK_MENU')}
            </NavLink.Anchor>
          </li>
          <li>
            <NavLink.Anchor
              href={ROUTES.AboutUs.url({}, locale)}
              underline="none"
            >
              <InfoIcon />
              {T('LBL_MENU_ABOUT_KAMERNET')}
            </NavLink.Anchor>
          </li>
          <li>
            <NavLink.Anchor
              href={ROUTES.SafetyTenant.url({}, locale)}
              underline="none"
            >
              <ShieldIcon />
              {T('LBL_GLOBAL_SAFETY')}
            </NavLink.Anchor>
          </li>
          <li>
            <NavLink.Native
              href={
                locale === Locale.en
                  ? '/tips/international-students'
                  : '/tips/studenten'
              }
              underline="none"
            >
              <LightbulbIcon />
              {T('LBL_USEFUL_TIPS_MENU')}
            </NavLink.Native>
          </li>
          <li>
            <NavLink.Native
              href={
                locale === Locale.en
                  ? 'https://support.kamernet.nl/en'
                  : 'https://support.kamernet.nl'
              }
              underline="none"
            >
              <HelpIcon />
              {T('LBL_FOOTER_SUPPORT')}
            </NavLink.Native>
          </li>
          {isAuthenticated && (
            <li className={styles.popoverBorder}>
              <button
                className={styles.popoverLogoutButton}
                type="button"
                onClick={signout}
              >
                <LogoutIcon />
                {T('LBL_LOGOUT_MENU')}
              </button>
            </li>
          )}
        </ul>
      </Popover>
    </React.Fragment>
  );
};
