import * as React from 'react';

import { useRouter } from 'next/router';

import { AppServicesContext } from '../AppServices';
import { useIntl } from '../Intl';
import { ROUTES, getRouteByPathname } from '../Routing';

import { AuthSource, AuthAction, UserPageRole } from './types';

type RouteParams = Parameters<typeof ROUTES.Signin.url>[0];

interface IAuthenticateUser {
  action: AuthAction;
  source: AuthSource;
  userPageRole?: UserPageRole;
}

export function useAuthenticationRouting() {
  const router = useRouter();
  const { locale } = useIntl();
  const { featureFlags } = AppServicesContext.useContext();
  const isLandlordRoute = getRouteByPathname(router.asPath)?.isLandlordRoute;

  // @todo After IDP experiment, change name to getAutheticationURL. It should return a URL that is used in Link component. Links are better for usability and accessibility than imperative navigation code
  const navigateToAuthentication = React.useCallback(
    ({ action, source, userPageRole }: IAuthenticateUser) => {
      const effectiveUserPageRole =
        userPageRole ??
        (isLandlordRoute ? UserPageRole.Landlord : UserPageRole.Tenant);

      const useIDP = featureFlags.use_idp === 'on';

      const urlParams: RouteParams = {
        ...(effectiveUserPageRole === UserPageRole.Landlord && {
          isUserLandlord: 'true',
        }),
        ...{ source },
        prevUrl: window.location.href,
      };

      const route = action === AuthAction.Login ? ROUTES.Signin : ROUTES.Signup;
      const url = useIDP
        ? route.url(urlParams, locale)
        : ROUTES.LegacyLogin.url(
            { returnUrl: router.asPath },
            locale,
            'prefix',
          );

      window.location.assign(url);
    },
    [featureFlags.use_idp, isLandlordRoute, locale, router.asPath],
  );

  return { navigateToAuthentication };
}
