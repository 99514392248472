import { UserProfile } from '@kamernet/core/ApiClient/Kamernet';

export interface IAuthState {
  userProfile: UserProfile | null;
  anonymousId: string | null;
  accessToken: string | null;
}

export type IAuth = IAuthState & {
  isAuthenticated: boolean;
  signout: () => void;
};

export enum AuthSource {
  ListingFavouriting = 'favorite_listing',
  SearchAlert = 'search_alert',
  ClickedNavbar = 'navbar',
}

export enum AuthAction {
  Login = 'Login',
  Signup = 'Signup',
}

export enum UserPageRole {
  Landlord = 'Landlord',
  Tenant = 'Tenant',
}
