import { NavLink } from '@kamernet/core/Routing/NavLink';
import LogoIcon from '@kamernet/icons/LogoPartOfHA.svg';
import { useIntl } from '@kamernet/core/Intl';
import { ROUTES } from '@kamernet/core/Routing';

import styles from './LogoLink.module.css';

export const LogoLink = () => {
  const { locale } = useIntl();

  return (
    <NavLink.Anchor href={ROUTES.Home.url({}, locale)} className={styles.root}>
      <LogoIcon alt="Kamernet" viewBox="0 0 141 41" />
    </NavLink.Anchor>
  );
};
