import getConfig from 'next/config';

import { ImageSize } from './types';

const { publicRuntimeConfig } = getConfig();

export const getImageUrl = (
  imageGuid: string | undefined,
  size?: ImageSize,
) => {
  const url = `${publicRuntimeConfig.KAMERNET_RESOURCE_ORIGIN}/image/${imageGuid}`;

  if (size) {
    return `${url}/resize/${size.width}-${size.height}`;
  }

  return url;
};
