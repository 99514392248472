import React from 'react';

import { useMediaIsDesktop } from '@kamernet/utilities/Media/useMediaIsDesktop';

import styles from './Header.module.css';
import { HeaderDesktop } from './HeaderDesktop';
import { HeaderMobile } from './HeaderMobile';

export const Header = () => {
  const isDesktop = useMediaIsDesktop();

  return (
    <header id="page-header" data-testid="PageHeader" className={styles.root}>
      {isDesktop ? <HeaderDesktop /> : <HeaderMobile />}
    </header>
  );
};
