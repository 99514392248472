import React from 'react';

import { useRouter } from 'next/router';

import { LinearProgress, LinearProgressProps } from '@mui/material';

import { isDevelopment } from '@kamernet/core/Environment';

import styles from './LoadingIndicator.module.css';

export interface LoadingIndicatorProps {
  loading: boolean;
}

export const LoadingIndicator = ({ loading }: LoadingIndicatorProps) => {
  const router = useRouter();

  const [navigationState, setNavigationState] = React.useState<
    'idle' | 'loading'
  >('idle');

  let linearProgressColor: LinearProgressProps['color'] = 'primary';

  if (isDevelopment()) {
    if (loading && navigationState === 'loading') {
      linearProgressColor = 'error';
    } else if (loading) {
      linearProgressColor = 'secondary';
    }
  }

  React.useEffect(() => {
    const onStart = (_url: string, { shallow }: { shallow: boolean }) => {
      if (shallow) {
        return;
      }
      setNavigationState('loading');
    };

    const onEnd = () => {
      setNavigationState('idle');
    };

    router.events.on('routeChangeStart', onStart);
    router.events.on('routeChangeComplete', onEnd);
    router.events.on('routeChangeError', onEnd);

    return () => {
      router.events.off('routeChangeStart', onStart);
      router.events.off('routeChangeComplete', onEnd);
      router.events.off('routeChangeError', onEnd);
    };
  }, [router.events]);

  return (
    (loading || navigationState === 'loading') && (
      <LinearProgress
        color={linearProgressColor}
        data-loading-type={loading ? 'data' : 'navigation'}
        data-navigation-state={navigationState}
        data-testid="top-loading-indicator"
        className={styles.root}
      />
    )
  );
};
