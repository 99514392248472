import React from 'react';

import { useIntl } from '@kamernet/core/Intl';
import { ROUTES } from '@kamernet/core/Routing';
import { NavLink } from '@kamernet/core/Routing/NavLink';
import LogoIcon from '@kamernet/icons/LogoPartOfHA.svg';

import styles from './SimpleHeader.module.css';
import { Container } from '@mui/system';

export const SimpleHeader = () => {
  const { locale } = useIntl();

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <NavLink.Anchor
          href={ROUTES.Home.url({}, locale)}
          className={styles.logo}
        >
          <LogoIcon alt="Kamernet" viewBox="0 0 141 41" />
        </NavLink.Anchor>
      </Container>
    </div>
  );
};
