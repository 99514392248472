import React from 'react';

import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

import { Drawer, Typography, IconButton } from '@mui/material';

import NoUserImage from '@kamernet/assets/NoUserImage.webp';
import { MyConversationsInboxType } from '@kamernet/core/ApiClient/Kamernet';
import { AuthContext } from '@kamernet/core/Auth/AuthContext';
import { AuthSource, AuthAction } from '@kamernet/core/Auth/types';
import { useAuthenticationRouting } from '@kamernet/core/Auth/useAuthenticationRouting';
import { useCookieBanner } from '@kamernet/core/Cookie/useCookieBanner';
import { useIntl } from '@kamernet/core/Intl';
import { Locale } from '@kamernet/core/Intl/types';
import { ROUTES } from '@kamernet/core/Routing';
import { NavLink } from '@kamernet/core/Routing/NavLink';
import BurgerIcon from '@kamernet/icons/Burger.svg';
import CrossIcon from '@kamernet/icons/Cross.svg';
import ENFlagIcon from '@kamernet/icons/ENFlag.svg';
import HomeIcon from '@kamernet/icons/Home.svg';
import NLFlagIcon from '@kamernet/icons/NLFlag.svg';
import PremiumIcon from '@kamernet/icons/Premium.svg';
import SearchIcon from '@kamernet/icons/Search.svg';

import styles from './HeaderMobile.module.css';
import { LogoLink } from './LogoLink';

export const HeaderMobile = () => {
  const router = useRouter();
  const { T, locale } = useIntl();
  const { isAuthenticated, userProfile, signout } = AuthContext.useContext();
  const { navigateToAuthentication } = useAuthenticationRouting();
  const { changeCookieBannerLanguage } = useCookieBanner();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const handleDrawerCloseButtonClick = () => setIsDrawerOpen(false);
  const handleDrawerOpenButtonClick = () => setIsDrawerOpen(true);
  const handleDrawerClose = () => setIsDrawerOpen(false);

  return (
    <React.Fragment>
      <nav className={styles.root}>
        <IconButton onClick={handleDrawerOpenButtonClick}>
          <BurgerIcon color="secondary" />
        </IconButton>
        <LogoLink />
      </nav>
      <Drawer open={isDrawerOpen} anchor="left" onClose={handleDrawerClose}>
        <ul className={styles.drawer}>
          <li className={styles.drawerHead}>
            <IconButton onClick={handleDrawerCloseButtonClick}>
              <CrossIcon fontSize="small" color="secondary" />
            </IconButton>
            {isAuthenticated && (
              <div className={styles.drawerProfileInfo}>
                <Image
                  src={NoUserImage}
                  alt="No User Image"
                  width={40}
                  height={40}
                />
                <Typography variant="body3">
                  {userProfile?.usernamefirst}
                </Typography>
              </div>
            )}
          </li>
          <li className={styles.drawerDivider} />
          <li>
            <NavLink.Anchor
              href={{ pathname: router.pathname, query: router.query }}
              locale={locale === 'en' ? 'nl' : 'en'}
              underline="none"
              onClick={changeCookieBannerLanguage}
            >
              {locale === 'en' ? (
                <NLFlagIcon fontSize="small" />
              ) : (
                <ENFlagIcon fontSize="small" />
              )}
              {T('LBL_GLOBAL_LANGUAGE')}
            </NavLink.Anchor>
          </li>
          {!isAuthenticated && (
            <React.Fragment>
              <li className={styles.drawerDivider} />
              <li>
                <Typography
                  variant="body2Bold"
                  onClick={() =>
                    navigateToAuthentication({
                      action: AuthAction.Login,
                      source: AuthSource.ClickedNavbar,
                    })
                  }
                >
                  {T('LBL_LOGIN')}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body2Bold"
                  onClick={() =>
                    navigateToAuthentication({
                      action: AuthAction.Signup,
                      source: AuthSource.ClickedNavbar,
                    })
                  }
                >
                  {T('LBL_BUTTON_REGISTER')}
                </Typography>
              </li>
            </React.Fragment>
          )}
          <li className={styles.drawerDivider} />
          <li>
            <NavLink.Anchor
              href={ROUTES.LCFStart.url({}, locale)}
              underline="none"
            >
              <HomeIcon fontSize="small" />
              {T('LBL_GLOBAL_CREATE_ADVERT')}
            </NavLink.Anchor>
          </li>
          <li>
            <NavLink.Anchor
              href={ROUTES.Search.url({}, locale)}
              underline="none"
            >
              <SearchIcon fontSize="small" />
              {T('LBL_SEARCH_ROOM')}
            </NavLink.Anchor>
          </li>
          <li>
            <NavLink.Native
              href={ROUTES.SearchTenant.url({}, locale, 'prefix')}
              underline="none"
            >
              <SearchIcon fontSize="small" />
              {T('LBL_SEARCH_TENANTS_MENU')}
            </NavLink.Native>
          </li>
          {isAuthenticated && (
            <React.Fragment>
              <li>
                <NavLink.Native
                  href={ROUTES.PremiumListing.url({}, locale, 'prefix')}
                  underline="none"
                >
                  <PremiumIcon fontSize="small" />
                  {T('LBL_GLOBAL_GET_PREMIUM')}
                </NavLink.Native>
              </li>
              <li className={styles.drawerDivider} />
              <li>
                <NavLink.Anchor
                  href={ROUTES.Dashboard.url({}, locale)}
                  underline="none"
                >
                  {T('LBL_GLOBAL_DASHBOARD')}
                </NavLink.Anchor>
              </li>
              <li>
                <NavLink.Anchor
                  href={ROUTES.MyConversations.url(
                    { inboxType: MyConversationsInboxType.Default },
                    locale,
                  )}
                  underline="none"
                >
                  {T('LBL_GLOBAL_MESSAGES')}
                </NavLink.Anchor>
              </li>
              <li>
                <NavLink.Anchor
                  href={ROUTES.MyAccount.url({}, locale)}
                  underline="none"
                >
                  {T('LBL_GLOBAL_MYACCOUNT')}
                </NavLink.Anchor>
              </li>
            </React.Fragment>
          )}
          {isAuthenticated && (
            <React.Fragment>
              <li className={styles.drawerDivider} />
              <li>
                <NavLink.Anchor
                  href={ROUTES.MyListings.url({}, locale)}
                  underline="none"
                >
                  {T('LBL_ADVERTS_MENU')}
                </NavLink.Anchor>
              </li>
              <li>
                <NavLink.Anchor
                  href={ROUTES.PublicProfile.url({}, locale)}
                  underline="none"
                >
                  {T('LBL_GLOBAL_PUBLIC_PROFILE')}
                </NavLink.Anchor>
              </li>
              <li>
                <NavLink.Anchor
                  href={ROUTES.AccountAlerts.url({}, locale)}
                  underline="none"
                >
                  {T('LBL_GLOBAL_ALERTS')}
                </NavLink.Anchor>
              </li>
              <li>
                <NavLink.Anchor
                  href={ROUTES.MyFavoriteRooms.url({}, locale)}
                  underline="none"
                >
                  {T('LBL_GLOBAL_FAVORITES')}
                </NavLink.Anchor>
              </li>
              <li>
                <NavLink.Anchor
                  href={ROUTES.Settings.url({}, locale)}
                  underline="none"
                >
                  {T('LBL_GLOBAL_SETTINGS')}
                </NavLink.Anchor>
              </li>
            </React.Fragment>
          )}
          <li className={styles.drawerDivider} />
          {isAuthenticated && (
            <li>
              <NavLink.Anchor
                href={ROUTES.ReferFriend.url({}, locale)}
                underline="none"
              >
                {T('LBL_GLOBAL_REFERRAL')}
              </NavLink.Anchor>
            </li>
          )}
          <li>
            <NavLink.Anchor
              href={ROUTES.Cashback.url({}, locale)}
              underline="none"
            >
              {T('LBL_GLOBAL_CASHBACK')}
            </NavLink.Anchor>
          </li>
          <li className={styles.drawerDivider} />
          <li>
            <NavLink.Anchor
              href={ROUTES.HowDoesItWorkTenant.url({}, locale)}
              underline="none"
            >
              {T('LBL_HOW_DOES_IT_WORK_MENU')}
            </NavLink.Anchor>
          </li>
          <li>
            <NavLink.Anchor
              href={ROUTES.AboutUs.url({}, locale)}
              underline="none"
            >
              {T('LBL_MENU_ABOUT_KAMERNET')}
            </NavLink.Anchor>
          </li>
          <li className={styles.drawerDivider} />
          <li>
            <NavLink.Anchor
              href={ROUTES.SafetyTenant.url({}, locale)}
              underline="none"
            >
              {T('LBL_GLOBAL_SAFETY')}
            </NavLink.Anchor>
          </li>
          <li>
            <NavLink.Native
              href={
                locale === Locale.en
                  ? '/tips/international-students'
                  : '/tips/studenten'
              }
              underline="none"
            >
              {T('LBL_USEFUL_TIPS_MENU')}
            </NavLink.Native>
          </li>
          <li>
            <NavLink.Native
              href={
                locale === Locale.en
                  ? 'https://support.kamernet.nl/en'
                  : 'https://support.kamernet.nl'
              }
              underline="none"
            >
              {T('LBL_FOOTER_SUPPORT')}
            </NavLink.Native>
          </li>
          {isAuthenticated && (
            <React.Fragment>
              <li className={styles.drawerDivider} />
              <li>
                <button
                  className={styles.drawerLogoutButton}
                  type="button"
                  onClick={signout}
                >
                  {T('LBL_LOGOUT_MENU')}
                </button>
              </li>
            </React.Fragment>
          )}
        </ul>
      </Drawer>
    </React.Fragment>
  );
};
