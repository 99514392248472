/* eslint-disable react/function-component-definition */
import React from 'react';

import getConfig from 'next/config';
import Head from 'next/head';

import { Typography, Container, ContainerProps } from '@mui/material';
import classNames from 'classnames';

import { CommonMessages } from '@kamernet/core/CommonMessages';
import { useIntl } from '@kamernet/core/Intl';
import { Locale } from '@kamernet/core/Intl/types';
import { Route, RouteParams } from '@kamernet/core/Routing';

import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { LoadingIndicator } from './components/LoadingIndicator';
import { SimpleHeader } from './components/SimpleHeader/SimpleHeader';
import styles from './PageLayout.module.css';

const { publicRuntimeConfig } = getConfig();

export interface PageLayoutProps<
  TRouteParams extends RouteParams = RouteParams,
> {
  pageTitle: string;
  metaDescription?: string;
  route?: Route<TRouteParams>;
  routeParams?: TRouteParams;
  nlRouteParams?: TRouteParams;
  loading?: boolean;
  children: React.ReactNode;
  className?: string;
  ogImage?: string;
  simpleHeader?: boolean;
  hideFooter?: boolean;
}

function getSEOHyperLinks<TRouteParams extends RouteParams>({
  route,
  routeParams,
  locale,
}: {
  route: Route<TRouteParams>;
  routeParams: TRouteParams;
  locale: Locale;
}): string {
  let routePath = route.url({ ...routeParams }, locale, 'prefix');

  if (routePath.endsWith('/')) {
    routePath = routePath.slice(0, -1);
  }
  const routeURL = publicRuntimeConfig.ORIGIN + routePath;

  return routeURL;
}

export const PageLayout = <TRouteParams extends RouteParams>({
  pageTitle,
  metaDescription,
  route = undefined,
  routeParams = {} as TRouteParams,
  nlRouteParams = {} as TRouteParams,
  loading = false,
  children,
  className,
  ogImage,
  simpleHeader,
  hideFooter,
}: PageLayoutProps<TRouteParams>) => {
  const { T, locale } = useIntl();

  const title = React.useMemo(() => {
    return `${pageTitle.replace(
      // eslint-disable-next-line custom/no-restricted-translation-concatenation-with-template-literal
      ` | ${T(CommonMessages.app_name)}`,
      '',
      // eslint-disable-next-line custom/no-restricted-translation-concatenation-with-template-literal
    )} | ${T(CommonMessages.app_name)}`;
  }, [T, pageTitle]);

  return (
    <React.Fragment>
      <Head>
        <title>{title}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {route && routeParams && nlRouteParams && (
          <React.Fragment>
            {locale === Locale.en ? (
              <link
                rel="canonical"
                href={getSEOHyperLinks({
                  route,
                  routeParams,
                  locale: Locale.en,
                })}
              />
            ) : (
              <link
                rel="canonical"
                href={getSEOHyperLinks({
                  route,
                  routeParams: nlRouteParams,
                  locale: Locale.nl,
                })}
              />
            )}
            <link
              rel="alternate"
              hrefLang="x-default"
              href={getSEOHyperLinks({
                route,
                routeParams,
                locale: Locale.en,
              })}
            />
            <link
              rel="alternate"
              hrefLang={Locale.en}
              href={getSEOHyperLinks({
                route,
                routeParams,
                locale: Locale.en,
              })}
            />
            <link
              rel="alternate"
              hrefLang={Locale.nl}
              href={getSEOHyperLinks({
                route,
                routeParams: nlRouteParams,
                locale: Locale.nl,
              })}
            />
          </React.Fragment>
        )}
        {ogImage && <meta property="og:image" content={ogImage} />}
      </Head>
      <div className={classNames(styles.root, className)}>
        {simpleHeader ? <SimpleHeader /> : <Header />}

        <LoadingIndicator loading={loading} />

        {children}

        {!hideFooter && <Footer />}
      </div>
    </React.Fragment>
  );
};

PageLayout.MainContainer = function PageLayoutMainContainer({
  children,
  ...props
}: Pick<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'> &
  Pick<ContainerProps, 'maxWidth'>) {
  return (
    <Container
      id="page-content"
      data-testid="PageContent"
      component="main"
      {...props}
    >
      {children}
    </Container>
  );
};

PageLayout.MainRaw = function PageLayoutMainRaw({
  children,
  className,
}: Pick<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'>) {
  return (
    <main id="page-content" data-testid="PageContent" className={className}>
      {children}
    </main>
  );
};

PageLayout.SectionRaw = function PageLayoutSectionRaw({
  id,
  'data-testid': testid,
  className,
  children,
}: Pick<
  React.HTMLAttributes<HTMLDivElement>,
  'id' | 'className' | 'children'
> & {
  'data-testid'?: string;
}) {
  return (
    <section id={id} data-testid={testid} className={className}>
      {children}
    </section>
  );
};

PageLayout.SectionContainer = function PageLayoutSectionContainer({
  id,
  'data-testid': testid,
  className,
  containerClassName,
  children,
}: Pick<
  React.HTMLAttributes<HTMLDivElement>,
  'id' | 'className' | 'children'
> & {
  'data-testid'?: string;
  containerClassName?: React.HTMLAttributes<HTMLDivElement>['className'];
}) {
  return (
    <section id={id} data-testid={testid} className={className}>
      <Container className={containerClassName}>{children}</Container>
    </section>
  );
};

PageLayout.Title = function PageLayoutTitle({
  children,
  className,
}: Pick<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'>) {
  return (
    <Typography
      variant="h2"
      data-testid="PageTitle"
      className={classNames(styles.pageTitle, className)}
    >
      {children}
    </Typography>
  );
};
