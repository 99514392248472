import { track } from '@hbf/analytics';

import { AnalyticsEvent } from '@kamernet/core/Analytics/types';

interface TrackExperimentParams {
  experimentName: string;
  condition: boolean;
  experimentPayload?: Record<string, any>;
}

export const trackExperiment = ({
  experimentName,
  condition,
  experimentPayload = {},
}: TrackExperimentParams) => {
  const trackPayload = {
    'Experiment name': experimentName,
    variant: condition ? 'B' : 'A',
    ...experimentPayload,
  };

  track(AnalyticsEvent.ExperimentStarted, trackPayload);
};
