/* eslint-disable @next/next/no-img-element */
import React from 'react';

import classNames from 'classnames';

import NoUserImage from '@kamernet/assets/NoUserImage.webp';
import { getImageUrl } from '@kamernet/utilities/Resources/getImageUrl';

import styles from './UserProfileImage.module.css';

export interface UserProfileImageProps {
  width: number;
  height: number;
  imageKey: string | null | undefined;
  className?: string;
}

export const UserProfileImage = ({
  imageKey,
  width,
  height,
  className = undefined,
}: UserProfileImageProps) => {
  const url = imageKey
    ? getImageUrl(imageKey, { width: 150, height: 150 })
    : NoUserImage.src;

  const errorHandler = React.useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const img = e.target as HTMLImageElement;
      img.onerror = null;
      img.src = NoUserImage.src;
    },
    [],
  );

  return (
    <img
      className={classNames(styles.root, className)}
      width={width}
      height={height}
      alt="user profile"
      src={url}
      loading="lazy"
      onError={errorHandler}
    />
  );
};
