import React from 'react';

import { Container } from '@mui/material';

import { useIntl } from '@kamernet/core/Intl';
import { Locale } from '@kamernet/core/Intl/types';
import { ROUTES } from '@kamernet/core/Routing';
import { NavLink } from '@kamernet/core/Routing/NavLink';
import KamernetLogo from '@kamernet/icons/LogoPartOfHA.svg';
import Facebook from '@kamernet/icons/Social/Facebook.svg';
import Instagram from '@kamernet/icons/Social/Instagram.svg';
import Pinterest from '@kamernet/icons/Social/Pinterest.svg';
import Snapchat from '@kamernet/icons/Social/Snapchat.svg';
import Twitter from '@kamernet/icons/Social/Twitter.svg';

import styles from './Footer.module.css';

export const Footer = () => {
  const { locale, T } = useIntl();

  return (
    <footer id="page-footer" data-testid="PageFooter" className={styles.root}>
      <Container>
        <div className={styles.siteLinksContainer}>
          <div>
            <div className={styles.logoKn}>
              <KamernetLogo alt="Kamernet" viewBox="0 0 141 41" />
            </div>

            <div className={styles.socialNetworkLinks}>
              <NavLink.Native
                href="https://www.facebook.com/kamernet"
                underline="none"
                color="secondary"
                target="_blank"
                rel="nofollow noopener"
              >
                <Facebook fontSize="small" className={styles.socialIcon} />
              </NavLink.Native>
              <NavLink.Native
                href="https://twitter.com/kamernet"
                underline="none"
                color="secondary"
                target="_blank"
                rel="nofollow noopener"
              >
                <Twitter
                  fontSize="small"
                  color="secondary"
                  className={styles.socialIcon}
                />
              </NavLink.Native>

              <NavLink.Native
                href="https://www.instagram.com/kamernet/"
                underline="none"
                color="secondary"
                target="_blank"
                rel="nofollow noopener"
              >
                <Instagram fontSize="small" className={styles.socialIcon} />
              </NavLink.Native>
              <NavLink.Native
                href="https://nl.pinterest.com/kamernet0599/"
                underline="none"
                color="secondary"
                target="_blank"
                rel="nofollow noopener"
              >
                <Pinterest fontSize="small" className={styles.socialIcon} />
              </NavLink.Native>
              <NavLink.Native
                href="https://www.snapchat.com/add/kamernetnl"
                underline="none"
                color="secondary"
                target="_blank"
                rel="nofollow noopener"
              >
                <Snapchat fontSize="small" className={styles.socialIcon} />
              </NavLink.Native>
            </div>
          </div>

          <div className={styles.linkColumn}>
            <NavLink.Anchor
              variant="body2"
              underline="none"
              href={ROUTES.AboutUs.url({}, locale)}
              color="mono.dark"
            >
              {T('LBL_MENU_ABOUT_KAMERNET')}
            </NavLink.Anchor>
            <NavLink.Native
              variant="body2"
              underline="none"
              rel="nofollow noopener"
              href="https://careers.smartrecruiters.com/kamernet"
              color="mono.dark"
              target="_blank"
            >
              {T('LBL_FOOTER_VACANCY')}
            </NavLink.Native>
            <NavLink.Anchor
              href={ROUTES.TermsAndConditions.url({}, locale)}
              color="mono.dark"
              variant="body2"
              underline="none"
            >
              {T('LBL_FOOTER_TERMS')}
            </NavLink.Anchor>
            <NavLink.Anchor
              href={ROUTES.PrivacyPolicy.url({}, locale)}
              color="mono.dark"
              variant="body2"
              underline="none"
            >
              {T('LBL_FOOTER_PRIVACY')}
            </NavLink.Anchor>
            <NavLink.Native
              href="https://kamernet.nl/sitemap"
              color="mono.dark"
              variant="body2"
              underline="none"
            >
              {T('LBL_FOOTER_SITEMAP')}
            </NavLink.Native>
          </div>
          <div className={styles.linkColumn}>
            <NavLink.Anchor
              href={ROUTES.HowDoesItWorkTenant.url({}, locale)}
              color="mono.dark"
              variant="body2"
              underline="none"
            >
              {T('LBL_PAGETITLE_HOW_DOES_IT_WORK')}
            </NavLink.Anchor>
            <NavLink.Native
              variant="body2"
              underline="none"
              href="https://support.kamernet.nl/en"
              color="mono.dark"
            >
              {T('LBL_FOOTER_CONTACT')}
            </NavLink.Native>

            <NavLink.Native
              variant="body2"
              underline="none"
              href="https://support.kamernet.nl/en"
              color="mono.dark"
            >
              {T('LBL_FOOTER_SUPPORT')}
            </NavLink.Native>
            <NavLink.Native
              href={
                locale === Locale.en
                  ? '/tips/international-students'
                  : '/tips/studenten'
              }
              variant="body2"
              underline="none"
              color="mono.dark"
            >
              {T('LBL_USEFUL_TIPS_MENU')}
            </NavLink.Native>
            <NavLink.Native
              variant="body2"
              underline="none"
              rel="nofollow"
              href={ROUTES.UnsubscribeEmail.url({}, locale, 'prefix')}
              color="mono.dark"
            >
              {T('LBL_FOOTER_EMAIL')}
            </NavLink.Native>
          </div>
          <div className={styles.linkColumn}>
            <NavLink.Native
              variant="body2"
              underline="none"
              rel="nofollow"
              href="https://register.daisycon.com/nl_NL/programinfo/index/program/447"
              color="mono.dark"
            >
              {T('LBL_FOOTER_AFFILIATES')}
            </NavLink.Native>
            <NavLink.Anchor
              variant="body2"
              underline="none"
              href={ROUTES.Partnerships.url({}, locale)}
              color="mono.dark"
            >
              {T('LBL_PARTNERSHIP')}
            </NavLink.Anchor>
            <NavLink.Native
              variant="body2"
              underline="none"
              href={ROUTES.RentOutHome.url({}, locale, 'prefix')}
              color="mono.dark"
            >
              {T('LBL_GLOBAL_SUPPLY_FOOTER_RENT_OUT_HOME_TITLE')}
            </NavLink.Native>
            <NavLink.Anchor
              variant="body2"
              underline="none"
              href={ROUTES.RentOutRoom.url({}, locale)}
              color="mono.dark"
            >
              {T('LBL_GLOBAL_SUPPLY_FOOTER_RENT_OUT_ROOM_TITLE')}
            </NavLink.Anchor>
            <NavLink.Anchor
              variant="body2"
              underline="none"
              href={ROUTES.SafetyLandlord.url({}, locale)}
              color="mono.dark"
            >
              {T('LBL_SAFETY_LANDLORD')}
            </NavLink.Anchor>
          </div>
        </div>
      </Container>
    </footer>
  );
};
